<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-tag type="danger" @click="ouit">退出</el-tag>
        <div v-if="agentinfo.pid == 0">
          我的子代理：
          <el-select
            v-model="sublevel_numbers"
            placeholder="请选择"
            @change="optionChange"
          >
            <el-option value="本人代理">本人代理</el-option>
            <el-option
              v-for="item in son"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="tit" @click="dataShow">
          <div>代理人：{{ agentinfo.name }}</div>
          <div>代理编号：{{ numbers }}</div>
        </div>
      </el-header>
      <el-main>
        <el-table
          style="width: 100%"
          :default-sort="{ prop: 'regdate', order: 'descending' }"
          :header-cell-style="{ background: '#960f23', color: '#FFFFFF' }"
          :data="
            tableData.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )
          "
        >
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="created_at" label="注册时间" sortable>
          </el-table-column>
          <el-table-column prop="order" label="付费时间">
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.order != null">{{
                rTime(scope.row.order)
              }}</span>
              <span v-if="scope.row.order == null">未付款</span>
            </template> -->
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            layout="total, prev, pager, next"
            :page-size="pagesize"
            :total="tableData.length"
            :current-page="currentPage"
          >
          </el-pagination>
        </div>
      </el-main>
      <el-dialog
        width="600px"
        :visible.sync="innerVisible"
        @close="closeCode"
        append-to-body
        :close-on-click-modal="false"
        title="代理人信息"
      >
        <div class="paycode">
          <div class="dialogtxt">
            <p>代理人编号：{{ numbers }}</p>
            <p v-if="agentinfo != null">代理人名称：{{ agentinfo.name }}</p>
            <p v-else>代理人名称：无</p>
            <p v-if="agentinfo != null">备注：{{ agentinfo.remark }}</p>
            <p v-else>备注：无</p>
            <p v-if="agentinfo != null">
              状态：
              <span v-if="agentinfo.status == 1">正常</span>
              <span v-if="agentinfo.status == 0">异常</span>
            </p>
            <p v-else>状态：无</p>
            <p>
              注册网址：<a
                :href="'http://www.wangpeishi.org.cn/' + this.numbers"
                >{{ 'http://www.wangpeishi.org.cn/' + this.numbers }}</a
              >
            </p>
            <p>二维码：</p>
          </div>
          <!-- 放置二维码的容器,需要给一个ref -->
          <div id="qrcode" ref="qrcode"></div>
        </div>
      </el-dialog>
    </el-container>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      numbers: '',
      currentPage: 1,
      pagesize: 20,
      innerVisible: false,
      tableData: [],
      agentinfo: {},
      son: [],
      sublevel_numbers: '本人代理',
    }
  },
  created() {
    if (this.getcookie()) {
      this.numbers = this.getcookie()
      this.init()
    } else {
      this.$router.push({
        name: 'MicroLogin',
      })
    }
  },
  methods: {
    init() {
      let _this = this
      _this.$http
        .post(process.env.VUE_APP_URL + 'agent', 'numbers=' + _this.numbers)
        .then(data => {
          _this.tableData = data.data.data
          _this.agentinfo = data.data.angent
          _this.son = data.data.son
        })
        .catch(err => {
          console.log(err)
        })
      // _this.$http
      //   .post(process.env.VUE_APP_ADMIN_URL + "agent")
      //   .then((data) => {
      //     if (data.data.code == 200) {
      //       this.username = data.data.data.filter(
      //         (x) => x.numbers == this.numbers
      //       )[0].name;
      //     } else {
      //       _this.$message.error(data.data.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     _this.$message.error("网络错误，请重试");
      //     console.log(err);
      //   });
    },
    optionChange(val) {
      let _this = this

      if (_this.sublevel_numbers == '本人代理') {
        val = ''
      }
      let postData = {
        id: val,
        numbers: _this.numbers,
      }
      _this.$http
        .post(process.env.VUE_APP_URL + 'agent', postData)
        .then(data => {
          _this.tableData = data.data.data
          _this.agentinfo = data.data.angent
          // _this.son = data.data.son
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage
    },
    ouit() {
      var exp = new Date()
      exp.setTime(exp.getTime() - 1)
      document.cookie =
        'AgentCode=' + escape(this.numbers) + ';expires=' + exp.toUTCString()
      this.$router.push({
        name: 'MicroLogin',
      })
    },
    rTime(date) {
      var json_date = new Date(date).toJSON()
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
    },
    getcookie() {
      var arr = document.cookie.split(';')
      for (var i = 0; i < arr.length; i++) {
        var arrcookie = arr[i].split('=')
        if (arrcookie[0].trim() == 'AgentCode') {
          return arrcookie[1]
        }
      }
      return false
    },
    dataShow() {
      this.innerVisible = true
      this.getCode()
    },
    getCode() {
      this.innerVisible = true
      // 二维码内容,一般是由后台返回的跳转链接,这里是写死的一个链接
      this.qrcode = 'http://www.wangpeishi.org.cn/' + this.numbers
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.crateQrcode()
      })
    },
    // 生成二维码
    crateQrcode() {
      this.qr = new QRCode('qrcode', {
        width: 300,
        height: 300, // 高度
        text: this.qrcode, // 二维码内容
        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'
        // foreground: '#ff0'
      })
    },
    // 关闭弹框,清除已经生成的二维码
    closeCode() {
      this.$refs.qrcode.innerHTML = ''
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.el-header {
  height: auto !important;
}
.tit {
  font-size: 20px;
  font-weight: bold;
  // text-align: right;
  // margin: 40px 80px 0px 0px;
  // float: right;
  cursor: pointer;
  // text-decoration: underline;
}
// .el-tag {
//   float: left;
//   width: 50px;
//   text-align: center;
//   position: relative;
//   top: 40px;
//   left: 80px;
// }
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px;
  margin-bottom: 0;
}
.el-tag:hover {
  cursor: pointer;
}
.el-main {
  padding: 20px 100px 50px;
}
/deep/.cell {
  text-align: center;
}
.el-table {
  min-height: 100vh;
}
.el-table thead {
  font-size: 18px;
}
/deep/tr.el-table__row {
  height: 70px;
}
.block {
  text-align: right;
}
/deep/.el-table__empty-text {
  line-height: 40;
}
/deep/.el-dialog__header {
  background-color: #960f23;
  padding: 20px;
  .el-dialog__title {
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    color: #ffffff;
  }
  .el-icon-close:before {
    font-size: 25px;
    color: #ffffff;
  }
}
#qrcode {
  width: 300px;
  height: 300px;
  margin: 10px auto;
}
.paycode {
  #title {
    font-size: 26px;
    text-align: center;
  }
  p {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
    a:hover {
      color: #960f23;
    }
  }
}
</style>
